import React from 'react'
import LayoutBase from '../layouts/LayoutBase'

const Terapias = ( ) => {
    return (
        <LayoutBase>
            <main className="container py-5">
                <h1>Terapias</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tempus elit blandit venenatis ultricies. Praesent euismod leo ac nulla aliquet elementum. Nullam iaculis sapien eu quam rutrum convallis. Phasellus sem leo, elementum sed imperdiet sed, ullamcorper a mauris. Proin eleifend quis quam vitae vehicula. Duis vitae consectetur tellus, volutpat euismod tortor. Aliquam a luctus lacus. Nullam efficitur augue felis, et tempor dui eleifend ac. Vivamus lacinia libero tellus. Fusce efficitur ante dolor, vel iaculis purus malesuada in. Etiam volutpat convallis lectus, vitae molestie risus consequat in.</p>

                <p>Fusce tincidunt sagittis purus vel placerat. Morbi auctor neque vitae sapien porttitor ultricies. Fusce vitae justo maximus, sodales enim non, mollis massa. Duis porttitor ipsum nec lorem bibendum, sollicitudin pulvinar libero iaculis. Quisque at dolor eu ex varius pharetra. Vivamus ornare ligula vitae augue volutpat feugiat. Nunc sed varius urna. Aliquam ultricies erat et ante tempus, vel cursus libero placerat. Fusce scelerisque feugiat leo, at porttitor nisi volutpat ac. Suspendisse pulvinar iaculis leo ut finibus. Nulla sit amet lorem nisl. Fusce ligula quam, sodales ac magna eu, fringilla bibendum enim. Vestibulum dictum enim in dui lobortis dapibus. Maecenas vel augue eget mauris placerat bibendum et non risus. Phasellus quis semper mauris.</p>
            </main>
        </LayoutBase>
    )
}

export default Terapias
